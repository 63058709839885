<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

//import { tableData } from "./dataAdvancedtable";
import Swal from "sweetalert2";
import axios from 'axios';

import "export-json-excel";

/**
 * Advanced table component
 */
export default {
  name: "log",
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  props: {
    menu:     String,
    entity:   String,
    addLink:  String,
    actions:  Object
  },
  data() {
    return {
      componentKey: 0,
      tableData: [],
      title: "Advanced Table",
      tableName: "",
      addAction: "",
      items: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "Advanced",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id_site",
      sortDesc: true,
      fields: [],
      fixedEntity: "",
      units: {
        'pool':     'pools',
        'schedule': 'schedules',
        'coach':    'coaches',
        'member':   'members',
        'group':    'groups',
        'team':     'teams'
      },
      validToken: true,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  //As the component us re-used for various modules, this WATCHER allows to reload the data.
  watch:{
    "entity": function(oldvalue, newvalue){
      if(oldvalue !== newvalue){
        this.setInit();
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.componentKey += 1;
    // Set the initial number of items
    this.totalRows  = this.items.length;

    this.setInit();
  },
  methods: {
    getFields(){
      let app = this;
      let ent = this.units[this.entity];
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/'+ent.replace(" ","")+'.php',
        {
          params: {
            request:  'getFields',
            token:    this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token'){
            this.validToken = false;
            Swal.close();
          }else{
            app.fields = response.data;
            app.sortBy = response.data[0].key;

            response.data.forEach(function(item){
              app.filterOn.push(item.field);
            });
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load fields! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },

    getRecords(){
      Swal.fire({
        title: "Loading data.",
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      let ent = this.units[this.entity];
      /*
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/'+ent.replace(" ","")+'.php',
        {
          params: {
            
            token: this.user.token,
          }
        }
      )
      */
      axios
      .post(window.serverPath+'dist/mvw/controllers/'+ent.replace(" ","")+'.php', {
        request: 'get'+this.capitalLetter(ent).replace(" ",""),
        token: this.user.token,
      })
      .then(response => {
        if( response.status == 200 ){
          if(response.data == 'Invalid token'){
            this.validToken = false;
            this.tableData = [];
          }else{
            if( Array.isArray(response.data) ){
              app.tableData = response.data;
            }else{
              app.tableData = [];
            }
            Swal.close();
          }
        }else{
          this.tableData = [];
          Swal.fire("Ooops!", "It was not possible to load data! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        this.tableData = [];
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    capitalLetter(string) {
      return ( string.charAt(0).toUpperCase() + string.slice(1) ).replace("-", " ");
    },


    setBreadcrum(){
      this.items = [
        {text: this.menu, href:"/"},
        {text: this.title.replace(" log", ""), active:true}
      ]
    },

    setInit(){
      this.fields     = [];
      this.tableData  = [];
      this.title      = this.capitalLetter(this.entity) + " log";
      this.tableName  = this.capitalLetter(this.entity) + " records.";
      this.addAction  = this.addLink;

      //console.log(this.entity.replace("-",""));

      this.setBreadcrum();
      this.getFields();
      this.getRecords();
    },

    printItem(item){
      console.log(item);
    }
  }
};
</script>

<template :key="componentKey">
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <h4 class="card-title">{{tableName}}</h4>
              </div>
              <div class="col-lg-6 text-md-right">
                <router-link :to="addAction">
                  <span class="btn btn-success"><i class="fas fas fa-plus"></i> Add {{capitalLetter(entity)}}</span>
                </router-link>

                <export-json-excel
                  v-if="tableData.length > 0"
                  :data="tableData"
                  class="btn btn-outline-dark ml-2"
                >
                  <span title="Download spreadsheet report">
                    <b-badge variant="dark"><i class="fas fa-download"></i></b-badge>
                    <b-badge variant="dark" class="ml-1"><i class="fas fa-file-excel"></i></b-badge>
                  </span>
                </export-json-excel>
              </div>
            </div>
            
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :hover="true"
                small
              >
                <!--
                <template v-if="row.item.de_status" v-slot:cell(de_status)="row">
                  <span v-if="row.item.de_status=='NEW' || row.item.de_status=='NEW'" class="badge badge-light">{{row.item.de_status}}</span>

                  <span v-if="row.item.de_status=='IN PROGRESS' || row.item.de_status=='In Progress'" class="badge badge-info">{{row.item.de_status}}</span>

                  <span v-if="row.item.de_status=='ON HOLD' || row.item.de_status=='On Hold'" class="badge badge-warning">{{row.item.de_status}}</span>

                  <span v-if="row.item.de_status=='COMPLETED' || row.item.de_status=='Completed'" class="badge badge-success">{{row.item.de_status}}</span>

                  <b-progress v-if="row.item.de_status == 'COMPLETED'" show-value height="15px" :value="row.item.progress" :max="100" class="mt-2" variant="success"></b-progress>
                  <b-progress v-else-if="row.item.de_status=='IN PROGRESS'" show-value height="15px" :value="row.item.progress" :max="100" class="mt-2" variant="info"></b-progress>
                  <b-progress v-else-if="row.item.de_status=='ON HOLD'" show-value height="15px" :value="row.item.progress" :max="100" class="mt-2" variant="warning"></b-progress>
                </template>
                -->

                <template v-slot:cell(url_photograph)="row">
                  <img v-if="row.item.url_photograph!=''" :src="window.serverPath+'/dist'+row.item.url_photograph" height="75px">
                  <i v-else class="fas fa-life-ring"></i>
                </template>

                <template v-slot:cell(actions)="row">
                  <button v-if="actions.view" size="sm" class="mr-1 btn btn-sm btn-info" @click="$router.push({ name: `${entity}`+'View', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button v-if="actions.edit" size="sm" class="mr-1 btn btn-sm btn-warning" @click="$router.push({ name: `${entity}`+'Edit', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button v-if="actions.start" size="sm" class="mr-1 btn btn-sm btn-primary" @click="$router.push({ name: `${entity}`+'Play', params: { id: `${row.item['id_'+entity.replace('-','')]}` } })">
                    <i class="fas fa-play"></i>
                  </button>
                  <!--
                  <button v-if="user.user.id_role <= 2" size="sm" class="mr-1 btn btn-sm btn-danger">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  -->
                  <!-- info(row.item, row.index, $event.target) -->
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style type="text/css">
.add-button {
  font-family: "Inter", sans-serif !important;
  font-size: 15px !important;
  margin: 0 0 7px 0;
  font-weight: 500;
}
.actionsFixed{
  width: 200px !important;
}
.table thead th {
    vertical-align: middle !important;
}
</style>